import FlexBox from '@/atomic-components/FlexBox';
import { Box } from '@mui/material';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import { IProductTile1 } from './IProductTile1';
import { LencoImage } from '@/atomic-components/Image';
import { getColor } from '@/utils/theme/colors';
import { productTile1Style as styles } from './style';
import { LineOfProduct } from '@/utils/enums';
import Link from 'next/link';
import { PdpUrlHelper } from '@/utils/seo-url-helper';

export default function ProductTile1(props: IProductTile1 ) {
	const { data } = props;

	const ProductTile1Reducer = () => {
		return {
			available: data.available,
			delivery: data.delivery,
			id: data.id,
			lineOfProduct: data.lineOfProduct,
			name: data.name,
			permalink: data.permalink,
			pricing: data.pricing,
			thumbnail: data.thumbnail,
			title: data.title,
			variants: data.variants,
			vertical: data.vertical,
		};
	};

	const ProductTile1Data = ProductTile1Reducer();

	return (
		<Link href={`${PdpUrlHelper({lop:ProductTile1Data.lineOfProduct,permalink:ProductTile1Data.permalink})}`}>
		<Box sx={{position:'relative',padding:'16px'}}>
			<Box sx={{width:'100%',marginBottom:'-42%',minHeight:'225px',borderRadius:'8px 8px 0 0',overflow:'hidden'}}>
				<LencoImage aspectRatio={ProductTile1Data.thumbnail.aspectRatio}
					url={ProductTile1Data.thumbnail.url}/>
			</Box>
			<Box sx={{background:getColor(''),boxShadow:'0px 8px 16px rgba(34, 34, 34, 0.08);',paddingTop:'calc(50% - 10px)',paddingBottom:'16px',borderRadius:'8px'}}>
			<LencoTypography
				sx={{
					color: getColor('Gray 0'),
					margin: '0 24px 8px 24px',
					WebkitLineClamp:2,
					overflow:'hidden',
					display:'-webkit-box',
					WebkitBoxOrient:'vertical',
					minHeight:'50px'
				}}
				variant='H11A_Medium'
			>
				{ProductTile1Data?.title || ProductTile1Data?.name}
			</LencoTypography>
			
				{LineOfProduct[
				ProductTile1Data.lineOfProduct as keyof typeof LineOfProduct
			].length > 0 && (
				<LencoTypography sx={styles.vertical} variant='Small_Medium'>
					{
						LineOfProduct[
							ProductTile1Data.lineOfProduct as keyof typeof LineOfProduct
						]
					}
				</LencoTypography>
			)}

			
			<FlexBox
				sx={{
					alignItems: 'center',
					marginLeft: '24px',
				}}
			>
				<LencoTypography variant='H11_SemiBold' color={getColor('Gray 0')}>
					{ProductTile1Data?.pricing?.basePrice?.displayValue || ProductTile1Data?.pricing?.monthlyRental?.displayValue}
				</LencoTypography>
				<Box
					sx={{
						background: getColor('Yellow -4'),
						maxWidth: '46px',
						borderRadius: '10px',
						paddingX: '4px',
						paddingY: '2px',
						marginLeft: '8px',
					}}
				>
					<LencoTypography variant='Tiny_SemiBold' color={getColor('Gray -1')}>
						{ProductTile1Data.pricing.discountPercentage.displayValue}
					</LencoTypography>
				</Box>
				<LencoTypography
					variant='Small_Medium'
					sx={{
						textDecoration: 'line-through',
						color: getColor('Gray -4'),
						marginLeft: '8px',
					}}
				>
					{ProductTile1Data?.pricing?.strikePrice?.displayValue}
				</LencoTypography>
			</FlexBox>
			</Box>
		</Box>	
		</Link>
	);
}
